import axios from "axios";
const dev = "http://127.0.0.1:3131";

const prod = "https://api.wekaala.com";

const api = axios.create({
  withCredentials: true,
  baseURL: prod,
});

export default api;
